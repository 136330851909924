@media print {
  header#app-bar {
    display: none;
  }

  div#drawer-menu {
    display: none;
  }

  main#main-container {
    margin: 0;
    padding: 0;
  }
}

@page {
  size: auto;
  margin: 0;
}
