div#shipping-print-wrap {
  display: none;
}

section.shipping-label-wrap {
  font-size: 12.5px;
  height: 2.1in;
  /*padding-top: 9mm;*/
  /*margin-bottom: 10mm;*/
  /*border: 1px solid red;*/
}

@page {
  /*size: 4in 2in !important;*/
  size: portrait;
  margin: 0 !important;
}

@media print {
  div#shipping-main-wrap {
    display: none;
  }

  div#shipping-print-wrap {
    display: block;
  }

}
