div#reception-print-wrap {
  display: none;
}

section.reception-label-wrap {
  font-size: 12.5px;
  height: 5cm;
  /*padding-top: 9mm;*/
  /*margin-bottom: 10mm;*/
  /*border: 1px solid red;*/
}

@page {
  size: 4in 5in !important;
  margin: 0 !important;
}

@media print {
  div#reception-main-wrap {
    display: none;
  }

  div#reception-print-wrap {
    display: block;
  }

  body {
    transform: scale(1);
  }
}
