div#courier-reception-print-wrap {
  display: none;
}

section.courier-reception-label-wrap {
  font-size: 12.5px;
  height: 5cm;
}

@page {
  size: 4in 5in !important;
  margin: 0 !important;
}

@media print {
  div#courier-reception-main-wrap {
    display: none;
  }

  div#courier-reception-print-wrap {
    display: block;
  }

  body {
    transform: scale(1);
  }
}
